const showModal = (e) => {

    const btn = document.querySelectorAll('[data-show-modal]')

    if(btn.length == 0) return;

    const find = (node) => {
        while (node) {

            if (node.hasAttribute('data-notshow-modal')) {

                return 0;
            } else {
                node = node.parentElement;
            }
        }
        return 1;
    }


    btn.forEach(elem => {
       
        let overlay = document.querySelector("." + elem.getAttribute('data-show-modal'))

        if(!overlay) return;

        const closes = overlay.querySelectorAll('.js--overlay-close')

        const notshow = elem.querySelectorAll('[data-notshow-modal]');
        const notshowBtn = elem.querySelectorAll('[data-show-modal]');


        elem.addEventListener('click', function(e) {
            let show = 1;
            show = find(e.target);
            notshow.forEach(item => {

                if (e.target == item) {
                    show = 0;
                    return;
                }
            });

            if(show) {
                notshowBtn.forEach(item => {

                    if (e.target == item) {
                        show = 0;
                        return;
                    }
                });
            }

            const closestModal = document.querySelectorAll('.overlay-show');
            if(closestModal.length != 0) {
                closestModal.forEach(el => {
                    el.classList.remove('overlay-show');
                    el.style.zIndex = -1
                })
            }

            if(overlay.classList.contains('overlay-card')) {
                overlay.querySelector('.overlay-wrap').scrollTo(0, 0);

                if(elem.getAttribute('data-modal-title')) overlay.querySelector('h2').innerHTML = elem.getAttribute('data-modal-title');
                if(elem.getAttribute('data-modal-address')) overlay.querySelector('p.overlay-address').innerHTML = elem.getAttribute('data-modal-address');
                if(elem.getAttribute('data-modal-img'))  overlay.querySelector('.overlay-image img').src = elem.getAttribute('data-modal-img');
                if(elem.getAttribute('data-modal-img'))  overlay.querySelector('.overlay-image img').alt = elem.getAttribute('data-modal-title');
                if(elem.getAttribute('data-modal-apart'))  overlay.querySelector('p.overlay-apart').innerHTML = elem.getAttribute('data-modal-apart');
                if(elem.getAttribute('data-modal-price'))  overlay.querySelector('p.overlay-price').innerHTML = elem.getAttribute('data-modal-price');
                if(elem.getAttribute('data-modal-price-min'))  overlay.querySelector('p.overlay-price-min').innerHTML = elem.getAttribute('data-modal-price-min');
                if(elem.getAttribute('data-modal-area'))  overlay.querySelector('p.overlay-area').innerHTML = elem.getAttribute('data-modal-area');
                if(elem.getAttribute('data-modal-describtion'))  overlay.querySelector('div.overlay-describtion').innerHTML = elem.getAttribute('data-modal-describtion');
                if(elem.getAttribute('data-modal-ch-11'))  overlay.querySelector('p.overlay-ch-11').innerHTML = elem.getAttribute('data-modal-ch-11');
                if(elem.getAttribute('data-modal-ch-12'))  overlay.querySelector('p.overlay-ch-12').innerHTML = elem.getAttribute('data-modal-ch-12');
                if(elem.getAttribute('data-modal-ch-21'))  overlay.querySelector('p.overlay-ch-21').innerHTML = elem.getAttribute('data-modal-ch-21');
                if(elem.getAttribute('data-modal-ch-22'))  overlay.querySelector('p.overlay-ch-22').innerHTML = elem.getAttribute('data-modal-ch-22');
                if(elem.getAttribute('data-modal-ch-31'))  overlay.querySelector('p.overlay-ch-31').innerHTML = elem.getAttribute('data-modal-ch-31');
                if(elem.getAttribute('data-modal-ch-32'))  overlay.querySelector('p.overlay-ch-32').innerHTML = elem.getAttribute('data-modal-ch-32');
                if(elem.getAttribute('data-modal-ch-41'))  overlay.querySelector('p.overlay-ch-41').innerHTML = elem.getAttribute('data-modal-ch-41');
                if(elem.getAttribute('data-modal-ch-42'))  overlay.querySelector('p.overlay-ch-42').innerHTML = elem.getAttribute('data-modal-ch-42');

                overlay.querySelector('.overlay-wrap__content').style.marginTop = overlay.querySelector('.overlay-wrap__top').scrollHeight + 24 + 'px';
            }

            e.preventDefault();
            if(!show) return;


            document.documentElement.style.overflowY = 'hidden';

            overlay.style.zIndex = 999;
            elem.disabled = true;

            setTimeout(() => {
                overlay.classList.add('overlay-show')
                elem.disabled = false
            }, 0)
        });
        if(closes.length != 0) {
            closes.forEach(close => {
                close.addEventListener('click', function(e) {
                    e.preventDefault()
                    clickClose()
                })
            })

        }

        overlay.addEventListener('click', function(e) {
            if (e.target != overlay) return
            clickClose()
        })

        const clickClose = () => {
            if (getComputedStyle(overlay.querySelector('.overlay-wrap')).marginRight != '0px') return
            overlay.classList.remove('overlay-show')
            setTimeout(() => {
                overlay.style.zIndex = -1
                document.documentElement.style.overflowY = 'auto'
            }, 300)
        }
    })
}

const closeModalMessage = (e) => {
    const message = document.querySelector('.js--message');

    if(!message) return;

    message.addEventListener('click', () => {
        message.classList.remove('open')
    });

    const timer = message.getAttribute('data-message-timer');

    let observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            if(mutation.type === 'attributes' && message.classList.contains("open") && !message.classList.contains("light")) {
                const modal = document.querySelector('.overlay-show');
         
                if(modal) {
                    message.classList.add('light');
                } 
                setTimeout(() => {
                    message.classList.remove('open');
                    message.classList.remove('light');
                }, timer);
          
            }
        });    
    });

    let config = {
        attributes: true 
    };
    observer.observe(message,  config);
}

export { showModal, closeModalMessage };

