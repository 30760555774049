import Swiper, { Navigation } from 'swiper';

const lazyLoadSliderComplex = () => {
    const wrap = document.querySelectorAll(".js--leazy-slider-card");

    if(wrap.length == 0) return;

    const options = {
        threshold: [0.1]
    }

    

    const callback = function(entries, observer) {
        entries.forEach(entry => {
            const {target, isIntersecting, intersectionRatio} = entry;
            if(isIntersecting && !target.classList.contains('js--swiper-active')) {
                target.classList.add('js--swiper-active')
                
                const sliders = document.querySelectorAll('.js--swiper-card');


                if(sliders.length != 0) {
                    sliders.forEach(slider=> {

                        new Swiper('#'+ slider.id + ' .swiper', {
                            modules: [Navigation],
                            slidesPerView: 'auto',
                            spaceBetween: 6,
                            navigation: {
                                nextEl: '#'+ slider.id + ' .card-slider__arrow--next',
                                prevEl: '#'+ slider.id + ' .card-slider__arrow--prev',
                            },
                        });
                    })
                }
            }
        });
    }

    const observer = new IntersectionObserver(callback, options);

    wrap.forEach(elem => {
        observer.observe(elem);
    })

};

export { 
    lazyLoadSliderComplex 
};
