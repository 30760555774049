import _clickOutside from "../../../js/_clickOutside.js";
const fixedMenu = () => {
    let scl = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scl > 50) {
        document.querySelector('header').classList.add('fixed')
    }
    if (scl < 50) {
        document.querySelector('header').classList.remove('fixed')
    }

    document.addEventListener('scroll', function () {
        let scl = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scl > 50) {
            document.querySelector('header').classList.add('fixed')
        }
        if (scl < 50) {
            document.querySelector('header').classList.remove('fixed')
        }

    })

}

const openSubMenu = () => {
    const subMenu = document.querySelectorAll('.sub-menu');

    if (subMenu.length == 0) return;

    subMenu.forEach(elem => {
        const title = elem.querySelector('.sub-menu__title');
        const list = elem.querySelector('.sub-menu__list');


        const li = title.closest('li');

        title.addEventListener('click', () => {


            _clickOutside(title, list, 'sub-menu--show', 'active');
            if (!list.classList.contains('sub-menu--show')) {
                list.classList.add('sub-menu--show');
                li.classList.add('active');
            } else {
                list.classList.remove('nav__sub-menu--show');
                li.classList.remove('active');
            }
        });

        li.addEventListener('mouseenter', () => {
            if(document.documentElement.clientWidth < 940) return;
            _clickOutside(title, list, 'sub-menu--show', 'active');
            list.classList.add('sub-menu--show');
            li.classList.add('active');

        });

        li.addEventListener('mouseleave', () => {
            if(document.documentElement.clientWidth < 940) return;
            _clickOutside(title, list, 'sub-menu--show', 'active');

            list.classList.remove('sub-menu--show');
            li.classList.remove('active');

        });
    })
}
export {
    fixedMenu,
    openSubMenu
}
