const quiz = () => {

    const quizNext = document.querySelector('.quiz-next')
    const quizPrev = document.querySelector('.quiz-prev')
    const quizTitle = document.querySelectorAll('.quiz-nav p')
    const quizNav = document.querySelectorAll('.quiz-nav')

  
    quizNext.addEventListener('click', () => {
      const content = document.querySelectorAll('.quiz-content');
      let index = 0;
  
      content.forEach((elem, item) => {
        if (elem.classList.contains('quiz-content-show')) index = item
      })
      let show = 0;
      switch (index) {
        case 0:
          show = 0;
          const inputs = content[0].querySelectorAll('input');
          inputs.forEach(elem => {
            if(elem.checked) show = 1
          })
          if(show){
            content[0].classList.remove('quiz-content-show')
            content[1].classList.add('quiz-content-show')
          }
          
          break;
  
        case 1:
          show = 0;
          const inputs2 = content[1].querySelectorAll('input');
          inputs2.forEach(elem => {
            if(elem.checked) show = 1
          })
          if(show){
            content[1].classList.remove('quiz-content-show')
            content[2].classList.add('quiz-content-show')
          }
         
          break;
  
        case 2:
          show = 0;
          const inputs3 = content[2].querySelectorAll('input');
          inputs3.forEach(elem => {
            if(elem.checked) show = 1
          })
          if(show){
            content[2].classList.remove('quiz-content-show')
            content[3].classList.add('quiz-content-show')
            document.querySelector('.quiz-next').classList.add('d-none');
    
            var answer1 = Array.from(document.querySelectorAll('.quiz-content-1 input:checked')).map(cb => cb.value);
            var answer2 = Array.from(document.querySelectorAll('.quiz-content-2 input:checked')).map(cb => cb.value);
            var answer3 = Array.from(document.querySelectorAll('.quiz-content-3 input:checked')).map(cb => cb.value);
    
            document.querySelector('#_quiz .quiz-answers input[name=user_form]').value = `Заявка с квиза. Комнатность: ${answer1}; Условия покупки: ${answer2}; Стоимость: ${answer3}`
          }
          
          break;
  
        default:
          break;
      }
      if(show){
        quizTitle.forEach((elem) => {
          elem.classList.remove('active')
        })
    
        quizTitle.forEach(() => {
          const max = index + 1
          for (let i = 0; i < max + 1; i++)
            quizTitle[i].classList.add('active')
        })
      }
  
     
  
      return
    })
  
    quizPrev.addEventListener('click', () => {
      const content = document.querySelectorAll('.quiz-content');
      let index = 0;
  
      content.forEach((elem, item) => {
        if (elem.classList.contains('quiz-content-show')) {
          index = item
          return
        }
      })
  
      switch (index) {
        case 1:
          content[1].classList.remove('quiz-content-show')
          content[0].classList.add('quiz-content-show')
          document.querySelector('.quiz-next').classList.remove('d-none');
          break;
  
        case 2:
          content[2].classList.remove('quiz-content-show')
          content[1].classList.add('quiz-content-show')
          document.querySelector('.quiz-next').classList.remove('d-none');
          break;
  
        case 3:
          content[3].classList.remove('quiz-content-show')
          content[2].classList.add('quiz-content-show')
          document.querySelector('.quiz-next').classList.remove('d-none');
          break;
  
        default:
          break;
      }
  
      quizTitle.forEach((elem) => {
        elem.classList.remove('active')
      })
  
      quizTitle.forEach(() => {
        const max = index - 1
        if (max == -1) {
          quizTitle[0].classList.add('active')
          return
        }
  
        for (let i = 0; i < max + 1; i++)
          quizTitle[i].classList.add('active')
      })
  
      return
    })
  
};

export {
    quiz
}