import Swiper, { Navigation } from 'swiper';

const lazyLoadSliderAbout = () => {
    const wrap = document.querySelectorAll(".js--leazy-slider-about");

    if(wrap.length == 0) return;

    const options = {
        threshold: [0.1]
    }

    const callback = function(entries, observer) {
        entries.forEach(entry => {
            const {target, isIntersecting, intersectionRatio} = entry;
            if(isIntersecting && !target.classList.contains('js--swiper-active')) {
                target.classList.add('js--swiper-active')
                
                new Swiper(".js--swiper-panorama", {
                    slidesPerView: 'auto',
                    modules: [Navigation],
                    spaceBetween: 12,
                    
                    navigation: {
                        nextEl: '.panorama-slider__arrow--next',
                        prevEl: '.panorama-slider__arrow--prev',
                    },
                });
            }
        });
    }

    const observer = new IntersectionObserver(callback, options);

    wrap.forEach(elem => {
        observer.observe(elem);
    })

};

export { 
    lazyLoadSliderAbout 
};
