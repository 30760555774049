
const lazyLoadPucture = () => {
    const wrap = document.querySelectorAll(".js--lazy-wrapper");

    if(wrap.length == 0) return;

    const options = {
        threshold: [0.1]
    }

    const callback = function(entries, observer) {
        entries.forEach(entry => {
            const {target, isIntersecting, intersectionRatio} = entry;
            if(isIntersecting && !target.classList.contains('js--lazy-active')) {

                target.classList.add('js--lazy-active')
                const images = target.querySelectorAll('.js--lazy-element');
                let alt = 'Изображение';
                let title = 'Изображение';

                images.forEach(elem => {
                    const parrent = elem.parentNode;
                    elem.parentNode.removeChild(elem);

                    if(!elem.getAttribute('data-lazy')) return;
                    if(elem.getAttribute('data-photo-alt')) {
                        alt = elem.getAttribute('data-photo-alt');
                    }
                    if(elem.getAttribute('data-photo-title')) {
                        title = elem.getAttribute('data-photo-title');
                    }


                    let div = document.createElement('picture');
                    div.classList.add('d-flex', 'justify-content-center', 'w-100')
                    let content = ``
                    if(elem.getAttribute('data-lazy-xs')) {
                        content += `<source media="(max-width: 575px)" srcset="${elem.getAttribute('data-lazy-xs')}">`
                    }
                    if(elem.getAttribute('data-lazy-md')) {
                        content += `<source media="(max-width: 991px)" srcset="${elem.getAttribute('data-lazy-md')}">`
                    }
                    content += `
                            <img src="${elem.getAttribute('data-lazy')}" class="w-100" alt="${alt}" title="${title}">
                          </picture>`;

                    div.innerHTML = content;
                    parrent.appendChild(div);
                })


            }
        });
    }

    const observer = new IntersectionObserver(callback, options);

    wrap.forEach(elem => {
        observer.observe(elem);
    })

};
export default lazyLoadPucture;