/**
 * 
 * Ф-я для плавного скролла по якорям
 * 
 */

const scrolling = () => {
    let links = document.querySelectorAll('[href^="#"]'),
        speed = 0.3;

        if(links.length == 0) return;


    links.forEach(link => {
        link.addEventListener('click', function (event) {
            event.preventDefault();

            let widthTop = document.documentElement.scrollTop - 80;
            let hash = this.hash;
            if(!document.querySelector(hash)) window.location.href = '/'+ hash;
         

            let toBlock = document.querySelector(hash).getBoundingClientRect().top;
            let start = null;

            

            requestAnimationFrame(step);

            function step(time) {
                if (start === null) {
                    start = time;
                }

                let progress = time - start,
                    r = (toBlock < 0 ? Math.max(widthTop - progress / speed, widthTop + toBlock) : Math.min(widthTop + progress / speed, widthTop + toBlock));

                document.documentElement.scrollTo(0, r);

                if (r != widthTop + toBlock) 
                    requestAnimationFrame(step);
                // else location.hash = hash;
            }
        });
    });
};
export default scrolling;