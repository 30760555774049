const fixedSochial = () => {
    const wrap = document.querySelector('.js--sochial');

    if(!wrap) return;

    const btn = wrap.querySelector('.js--sochial-btn');
    const content = wrap.querySelector('.js--sochial-content');

    if(!btn || !content) return;

    btn.addEventListener('click', () => {
        if (!content.classList.contains('show')) {
            content.classList.add('show');
            btn.classList.add('active');
        } else {
            content.classList.remove('show');
            btn.classList.remove('active');
        }
    })
}
export {
    fixedSochial
}