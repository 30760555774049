import Swiper, { Pagination, Autoplay, Navigation, Mousewheel } from 'swiper';

import lazyLoadPucture from './components/lazyLoadPucture.js';
import lazyLoadBackground from './components/lazyLoadBackground.js';
import scrolling from './components/scroll.js'

import changeBurger from '../components/elements/burger/burger.js';
import showMenu from '../components/elements/menu/menu.js';
import * as header from '../components/elements/header/header.js';
import rememberCookie from '../components/elements/cookie/cookie.js';
import {openSelect} from '../components/elements/select/select.js';
import {fixedSochial} from '../components/elements/sochial/sochial.js';

import {showModal} from '../components/elements/overlay/overlay.js';

import * as form from '../components/page-main/form/form.js';
import { lazyLoadSliderAbout } from '../components/page-main/about/about.js';
import { lazyLoadSliderComplex } from '../components/page-main/complex/complex.js';
import { quiz } from '../components/page-main/quiz/quiz.js';

import { showAnimation, showAnimationDown } from '../components/animation/animation.js';


window.addEventListener('load', () => {
    lazyLoadPucture();
    lazyLoadBackground();

    changeBurger();
    showMenu();
    header.fixedMenu();
    header.openSubMenu();
    rememberCookie();

    showAnimation();
    showAnimationDown();
    form.sendForm();
    scrolling();
    showModal();
    quiz();
    openSelect();

    lazyLoadSliderAbout();
    lazyLoadSliderComplex();
    fixedSochial();
    
    let mask_phone = document.querySelectorAll('.form_input-tell input');
    if (mask_phone) {
        for (let i = 0; i < mask_phone.length; i++) {
            form.maskPhone(mask_phone[i]);
        }
    }


    const ov = document.querySelector('.overlay-bid');
  
    setTimeout(() => { 
        if(ov && !ov.classList.contains('overlay-show')) {
            document.querySelector('.button-top').click();
        }
    }, 30000);
});